<template>
  <div class="home">
    <!-- size="small" -->
    <a-table :columns="columns" rowKey="reportId" :pagination="pagination" @change="handleTableChange" :data-source="data" size="middle" bordered>
      <template slot="option" slot-scope="text, record">
        &nbsp;
        <a-popconfirm placement="topRight"
          title="确定要删除这条记录吗?"
          ok-text="删除"
          cancel-text="取消"
          @confirm="confirmDelete(record)"
        >
          <a-button type="danger" shape="circle" icon="delete" />
        </a-popconfirm>
      </template>
      <template slot="name" slot-scope="text, record">
        <a target="_blank" :href="baseURL+'/trail/download/'+record.reportKey">{{text}}</a>
        
      </template>
      <template slot="size" slot-scope="text">
        {{ formatSize(text) }}
      </template>
      <template slot="time" slot-scope="text">
        {{ formatDate(text, 'yyyy-MM-dd HH:mm:ss') }}
      </template>
      <template slot="title" slot-scope="currentPageData">

        <a-input addon-before="报表名称" style="width: 350px" v-model="key" allow-clear />
        &nbsp;<a-button type="primary"  icon="search" @click="loadReportData(1)" :loading="searchLoading">搜索</a-button>
        <div class="rightTools" >
          <a-month-picker v-model="reportMonth" placeholder="选择月份" />&nbsp;&nbsp;
          <a-button type="primary" :disabled="exportStatus" :loading="exportStatus" @click="exportMonthReport">生成月报表</a-button>
        </div>
      </template>
      <template slot="footer" slot-scope="currentPageData" >
      </template>
    </a-table>
  </div>
</template>

<script>
import moment from 'moment';

const fData = function(date, fmt) {
      date = new Date(date);
      if (typeof(fmt) === "undefined") {
          fmt = "yyyy-MM-dd HH:mm:ss";
      }
      if (/(y+)/.test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      let o = {
          'M+': date.getMonth() + 1,
          'd+': date.getDate(),
          'H+': date.getHours(),
          'm+': date.getMinutes(),
          's+': date.getSeconds()
      }
      for (let k in o) {
          if (new RegExp(`(${k})`).test(fmt)) {
              let str = o[k] + ''
              fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length));
          }
      }
      return fmt
    }

const columns = [
  {
    title: '报表编号',
    align: 'center',
    dataIndex: 'reportId',
    width:80
  },{
    title: '报表名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'name' }
  },{
    title: '文件大小',
    width:110,
    align: 'right',
    dataIndex: 'contentSize',
    scopedSlots: { customRender: 'size' }
  },{
    title: '生成时间',
    width:200,
    align: 'right',
    dataIndex: 'createAt',
    scopedSlots: { customRender: 'time' }
  },
  {
    title: '操作',
    width:100,
    align: 'center',
    scopedSlots: { customRender: 'option' }
  }
];

let that = null;
export default {
  data:function(){
    return {
      moment,
      columns,
      exportStatus:false,
      reportMonth:null,
      formatDate:fData,
      data:[],
      pageSize:10,
      pagination:{current:1},
      searchLoading:false,
      key:'',
    }
  },
  mounted:function(){
    that = this;
    this.$ready(()=>{
      this.loadReportData(1);
    });
    
  },
  methods:{
    formatSize(size){
      if(size === 0){
        return "0";
      }
      let fsize = parseInt(size);
      if(isNaN(fsize)){
        return size;
      }
      if(fsize){
        return (fsize/1024).toFixed(2)+"  Kb";
      }
    },
    handleTableChange(pagination, filters, sorter){
      this.loadReportData(pagination.current);
    },

    exportMonthReport(){
      
      if(!this.reportMonth){
          this.$notification.error({
            message: '请先选择需要导出的月份',
            description:'请注意,这里月份取报表月份并非记录创建时间'
          });
        return;
      }

      this.exportStatus = true;
      console.log(this.reportMonth)
      this.$axios.get("/report/export-month",{
        params:{
          date:this.reportMonth.format('YYYY-MM')
        }
      }).then(resp=>{
        this.exportStatus = false;
        if(resp.data && resp.data["code"] == 200){
          this.$notification.success({
            message: '报表导出成功'
          });
          this.loadReportData(1);
        }else{
          this.$notification.error({
            message: '报表导出失败',
            description:
              resp.data.msg,
          });
        }
      })
    },

    loadReportData(page){
      this.searchLoading = true;
      ///trail/page
      this.$axios.get("/report/page",{
        params:{
          pageNo:page,
          pageSize:this.pageSize,
          key:this.key,
        }
      }).then(resp=>{
        this.searchLoading = false;
        if(resp.data && resp.data["code"] == 200){
          this.data = resp.data.data.records;

          const pagination = { ...this.pagination };
          pagination.current = parseInt(resp.data.data.current+"");
          pagination.pageSize = this.pageSize;
          pagination.total = parseInt(resp.data.data.total+"");
          this.pagination = pagination;

        }else{
          this.$notification.error({
            message: '数据获取失败',
            description:
              resp.data.msg,
          });
        }
      })

    },
    confirmDelete(item){
      this.$axios.post("/report/delete/"+item.reportId).then(resp=>{
        if(resp.data && resp.data["code"] == 200){
          this.loadReportData(this.pagination.current);
          this.$notification.success({
            message: '删除成功'
          });
        }else{
          this.$notification.error({
            message: '数据删除失败',
            description:
              resp.data.msg,
          });
        }
      })

    },
  }
}
</script>

<style>
.ant-table-title{
  text-align: left !important;
}
.rightTools{
  float: right;
}
.ant-form-item{
  margin-bottom: 6px !important;
}
</style>
